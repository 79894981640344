import { Ncc_ID } from "../constants";

/**
 * Điều hướng đến trang thông tin phù hợp dựa trên trạng thái dịch vụ và loại dịch vụ.
 *
 * @param {Object} params - Đối tượng chứa các tham số điều hướng.
 * @param {Function} params.navigate - Hàm điều hướng (thường được lấy từ react-router-dom).
 * @param {Object} params.record - Dữ liệu của dịch vụ cần điều hướng.
 * @param {string} params.status - "edit" | "view"
 * @param {number} params.keySide - id của nhà cung cấp hiện tại
 */
export const direct = ({ navigate, record, status, keySide }) => {
    if (!navigate || !record || !status) {
        console.error("Missing required parameters in directToPage");
        return;
    }

    if (!!record?.isDichVuKhac) {
        // Tìm tham số tương ứng với ID dịch vụ trong danh sách Ncc_ID
        const params = Object.keys(Ncc_ID || {}).find(
            (key) => Ncc_ID[key] === record.dmDvId
        );

        // Nếu tìm thấy tham số, điều hướng đến trang dịch vụ khác
        if (params) {
            return navigate(
                `/ncc/${params}/${status}?id=${record.id}&key=${record.dmDvId}`
            );
        }
    }

    // Điều hướng cho dịch vụ hiện tại dựa trên keySide
    switch (keySide) {
        case 1:
            return navigate(
                `/ncc/khach-san/${status}?id=${record.id}&key=${keySide}`
            );
        case 2:
            return navigate(
                "/ncc/oto/" + status + "?id=" + record.id + "&key=" + keySide
            );
        case 3:
            return navigate(
                "/ncc/nha-hang/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 4:
            return navigate(
                "/ncc/du-thuyen/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 5:
            return navigate(
                "/ncc/huong-dan-vien/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 6:
            return navigate(
                "/ncc/may-bay/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 7:
            return navigate(
                "/ncc/ve-thuyen/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 8:
            return navigate(
                "/ncc/visa/" + status + "?id=" + record.id + "&key=" + keySide
            );
        case 9:
            return navigate(
                "/ncc/ve-thang-canh/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 10:
            return navigate(
                "/ncc/ve-bus/" + status + "?id=" + record.id + "&key=" + keySide
            );
        case 11:
            return navigate(
                "/ncc/ve-tau/" + status + "?id=" + record.id + "&key=" + keySide
            );
        case 12:
            return navigate(
                "/ncc/dich-vu-khac/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 13:
            return navigate(
                "/ncc/land-tour/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        case 14:
            return navigate(
                "/ncc/huong-dan-vien-tu-do/" +
                    status +
                    "?id=" +
                    record.id +
                    "&key=" +
                    keySide
            );
        default:
            return null;
    }
};
