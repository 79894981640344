import React, { useEffect, useState, useRef } from "react";
import {
    notification,
    Row,
    Col,
    Card,
    Space,
    Skeleton,
    Button,
    Image,
    Switch,
    Tag,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, THE_HDV, TIME_DURATION } from "../../../constants";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import FormHdvTd from "./FormHdvTd";
import { useNavigate } from "react-router-dom";
import authHeader from "../../../api/auth_header";
import { direct } from "../../../helper";

const { Meta } = Card;
const SpHdvTuDo = ({ keySide, nameKey }) => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataSearch, setDataSearch] = useState();

    const navigate = useNavigate();
    const edit = (record) => {
        direct({
            navigate,
            record,
            status: "edit",
            keySide,
        });
    };

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    useEffect(() => {
        getData("", "", null);
    }, [keySide]);
    const getData = (name, sdt, params) => {
        if (keySide !== undefined && keySide.toString().length > 0) {
            let param = params
                ? params
                : {
                      hoVaTen: name,
                      loaiThe: null,
                      ngoaiNgu: null,
                      trangThai: null,
                      sdt: sdt,
                      email: null,
                      diaChi: null,
                      pageIndex: 1,
                      pageSize: 10,
                  };
            let data = {
                dmDichVuId: 14,
                searchParam: param,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "nnc/thongtinchung/getDataHdvTuDo",
                data: data,
                headers: authHeader(),
            })
                .then(function (response) {
                    console.log("Response: ====" + response.status);
                    if (response.status === 200) {
                        let data = response.data.data;
                        let dataArr = [];
                        let dataLstSearch = [];
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                dataArr.push({
                                    stt: i + 1,
                                    id: data[i].id,
                                    dmDichVuId: data[i].dmDichVuId,
                                    hoVaTen: data[i].hoVaTen,
                                    loaiTheHdv: data[i].loaiTheHdv,
                                    ngoaiNgu: data[i].ngoaiNgu,
                                    trangThai: data[i].trangThai,
                                    email: data[i].email,
                                    sdt: data[i].sdt,
                                    diaChi: data[i].diaChi,
                                    diaChiCuThe: data[i].diaChiCuThe,
                                    dichVu: data[i].dichVu,
                                    gioiTinh: data[i].gioiTinh,
                                    ngaySinh: data[i].ngaySinh,
                                    cccd: data[i].cccd,
                                    noiCap: data[i].noiCap,
                                    quocGiaId: data[i].quocGiaId,
                                    tinhId: data[i].tinhId,
                                    huyenId: data[i].huyenId,
                                    xaId: data[i].xaId,
                                    website: data[i].website,
                                    moTa: data[i].moTa,
                                    isCod: data[i].isCod,
                                    cod: data[i].cod,
                                    isVat: data[i].isVat,
                                    vatId: data[i].vatId,
                                    ghiChu: data[i].ghiChu,
                                    tenFile: data[i].tenFile,
                                    bucketName: data[i].bucketName,
                                    base64Value: data[i].base64Value,
                                    theHdvRequests: data[i].theHdvRequests,
                                    pdfFile: data[i].pdfFile,
                                    imgFile: data[i].imgFile,
                                });
                                dataLstSearch.push({
                                    value:
                                        data[i].id +
                                        " - " +
                                        data[i].hoVaTen +
                                        " - " +
                                        data[i].sdt,
                                });
                            }
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataArr);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleBorderChange = (record) => {
        let values = {
            id: record.id,
            hieu_luc: record.hieu_luc === true ? false : true,
            trangThai: record.trangThai === true ? false : true,
        };
        apiEdit(values, "hieu_luc");
    };
    const apiEdit = (values, name_key) => {
        axios({
            method: "post",
            url: API_BASE_URL + "/nnc/thongtinchung/updateStatus",
            data: values,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let updatedList = dataSource.map((item) => {
                    if (item.id === values.id) {
                        if (name_key === "trangThai") {
                            return { ...item, trangThai: values.trangThai };
                        } else {
                            return { ...item, hieu_luc: values.hieu_luc };
                        }
                    }
                    return item; // else return unmodified item
                });
                setDataSource(updatedList);
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
            }
        });
    };
    const onSearch = (params) => {
        // let data = [];
        // for (let i in dataSearch) {
        //     if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
        //         let item = dataSearch[i].value.split(" - ");
        //         data.push({
        //             stt: i,
        //             id: item[0],
        //             maNcc: item[1],
        //             tenNcc: item[2],
        //             email: item[3],
        //             sdt: item[4],
        //             diaChi: item[5],
        //             trangThai: item[6],
        //             tongSoDichVu: item[7],
        //         });
        //     }
        // }
        getData("", "", params);
    };
    const loaiThe = (data) => {
        let html = [];
        if (data === null || data.length < 1) {
            html.push(<p3>{t("cardNull")}</p3>);
        } else {
            let item = data.split(", ");
            for (let j = 0; j < item.length; j++) {
                if (item[j] === THE_HDV.QuocTe) {
                    html.push(
                        <Tag color="green">{t("internationalCard")}</Tag>
                    );
                }
                if (item[j] === THE_HDV.NoiDia) {
                    html.push(<Tag color="red">{t("localCard")}</Tag>);
                }
                if (item[j] === THE_HDV.DiaDiem) {
                    html.push(<Tag color="orange">{t("locationCard")}</Tag>);
                }
            }
        }
        return html;
    };
    const dataRender = () => {
        let html = [];
        for (let i = 0; i < dataSource.length; i++) {
            let typeThe = dataSource[i].loaiTheHdv;
            html.push(
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Card
                        bordered={true}
                        key={dataSource[i].id}
                        extra={
                            <Switch
                                defaultChecked={dataSource[i].trangThai}
                                onChange={() =>
                                    handleBorderChange(dataSource[i])
                                }
                            />
                        }
                        title={
                            <Button
                                type="link"
                                onClick={() => edit(dataSource[i])}
                            >
                                {dataSource[i].hoVaTen}
                            </Button>
                        }
                    >
                        <Skeleton loading={false} avatar active>
                            <Meta
                                avatar={
                                    <Image
                                        width={100}
                                        src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                                    />
                                }
                                description={
                                    <Space
                                        direction="vertical"
                                        size="small"
                                        style={{ display: "flex" }}
                                    >
                                        <Space
                                            direction="horizontal"
                                            size="small"
                                            style={{ display: "flex" }}
                                        >
                                            {loaiThe(typeThe)}
                                        </Space>
                                        {dataSource[i].ngoaiNgu !== null ? (
                                            <p>{dataSource[i].ngoaiNgu}</p>
                                        ) : null}
                                        <p>
                                            {dataSource[i].dichVu !== 0
                                                ? dataSource[i].dichVu +
                                                  " " +
                                                  t("service")
                                                : t("noService")}
                                        </p>
                                        <p>
                                            <PhoneOutlined />
                                            {dataSource[i].sdt}
                                            <MailOutlined
                                                style={{ marginLeft: "20px" }}
                                            />{" "}
                                            {dataSource[i].email}
                                        </p>
                                        <p>{dataSource[i].diaChi}</p>
                                    </Space>
                                }
                            />
                        </Skeleton>
                    </Card>
                </Col>
            );
        }
        return html;
    };
    return (
        <div style={{ marginLeft: "30px" }}>
            {contextHolder}
            <div
                style={{
                    marginBottom: 16,
                }}
            >
                <FormHdvTd
                    dataAdd={dataSource}
                    keySide={keySide}
                    handleSearch={onSearch}
                />
            </div>

            <Row gutter={16}>{dataRender()}</Row>
        </div>
    );
};
export default SpHdvTuDo;
