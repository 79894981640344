import { Box } from "@mui/material";
import { Affix, Col, Divider, Form, Input, Modal, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import ButtonSubmit from "../../../../layout/ButtonSubmit";
import InputMui from "../../../../layout/InputMui";

const { TextArea } = Input;
const ModalLpAction = ({
    isOpen,
    handleCancel,
    handleFinish,
    record,
    action,
}) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [loaiCabin, setLoaiCabin] = useState("");
    const [soLuong, setSoLuong] = useState("");
    const [soKhach, setSoKhach] = useState("");
    const [kichThuoc, setKichThuoc] = useState("");
    const [foc, setFoc] = useState("");
    const [poc, setPoc] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [hieuLuc, setHieuLuc] = useState("");
    const [loaiCabinError, setLoaiCabinError] = useState(false);
    const [soLuongError, setSoLuongError] = useState(false);
    const [soKhachError, setSoKhachError] = useState(false);
    const [focError, setFocError] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (!isEmpty(record)) {
            let apiData = {
                id: record.id,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "ncc/loaicabin/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data[0];
                        setId(data.id);
                        setNccThongTinChungId(data.nccThongTinChungId);
                        setLoaiCabin(data.loaiCabin);
                        setSoLuong(data.soLuong);
                        setSoKhach(data.soKhach);
                        setKichThuoc(data.kichThuoc);
                        setFoc(data.foc);
                        setPoc(data.poc);
                        setGhiChu(data.ghiChu);
                        setHieuLuc(data.hieuLuc);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [record.id]);
    const onFinish = (e) => {
        e.preventDefault();
        setLoaiCabinError(false);
        setSoLuongError(false);
        setSoKhachError(false);
        setSoKhachError(false);
        setFocError(false);
        var error = false;
        if (!loaiCabin) {
            setLoaiCabinError(true);
            error = true;
        }
        if (!soLuong) {
            setSoLuongError(true);
            error = true;
        }
        if (!soKhach) {
            setSoKhachError(true);
            error = true;
        }
        if (error) return;
        var values = {
            id: id,
            nccThongTinChungId: nccThongTinChungId,
            loaiCabin: loaiCabin,
            soLuong: soLuong,
            soKhach: soKhach,
            kichThuoc: kichThuoc,
            foc: foc,
            poc: poc,
            ghiChu: ghiChu,
            hieuLuc: hieuLuc,
        };
        form.resetFields();
        setOpen(false);
        handleFinish(open, values);
    };
    const handleCancels = () => {
        form.resetFields();
        setLoaiCabin("");
        setSoLuong("");
        setSoKhach("");
        setKichThuoc("");
        setFoc("");
        setPoc("");
        setGhiChu("");
        handleCancel();
    };
    const dataRender = () => {
        let html;

        html = (
            <>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <InputMui
                            label={t("roomType")}
                            onChange={(e) => setLoaiCabin(e.target.value)}
                            value={loaiCabin}
                            error={loaiCabinError}
                            helperText={
                                loaiCabinError
                                    ? t("validate") +
                                      " " +
                                      t("roomType").toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                        ></InputMui>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <InputMui
                            label={t("hotel.roomClass.numberOfRoom")}
                            onChange={(e) => setSoLuong(e.target.value)}
                            value={soLuong}
                            error={soLuongError}
                            helperText={
                                soLuongError
                                    ? t("validate") +
                                      " " +
                                      t(
                                          "hotel.roomClass.numberOfRoom"
                                      ).toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                <InputMui
                                    label={t("hotel.roomClass.guestMax")}
                                    onChange={(e) => setSoKhach(e.target.value)}
                                    value={soKhach}
                                    error={soKhachError}
                                    helperText={
                                        soKhachError
                                            ? t("validate") +
                                              " " +
                                              t(
                                                  "hotel.roomClass.guestMax"
                                              ).toLowerCase()
                                            : ""
                                    }
                                    required
                                    fullWidth
                                ></InputMui>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputMui
                                    disabled
                                    value={"/" + t("room")}
                                ></InputMui>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={12}>
                        <InputMui
                            label={t("hotel.roomClass.sizeRoomM2")}
                            onChange={(e) => setKichThuoc(e.target.value)}
                            value={kichThuoc}
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <InputMui
                            label={t("hotel.roomClass.conditionFoc")}
                            onChange={(e) => setFoc(e.target.value)}
                            value={foc}
                            error={focError}
                            helperText={
                                focError
                                    ? t("validate") +
                                      " " +
                                      t(
                                          "hotel.roomClass.conditionFoc"
                                      ).toLowerCase()
                                    : ""
                            }
                            fullWidth
                        ></InputMui>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputMui
                            label={t("hotel.roomClass.foc")}
                            onChange={(e) => setPoc(e.target.value)}
                            value={poc}
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <InputMui
                            label={t("notes")}
                            onChange={(e) => setGhiChu(e.target.value)}
                            value={ghiChu}
                            fullWidth
                        ></InputMui>
                    </Col>
                </Row>
            </>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const handleClear = () => {
        form.resetFields();
    };
    const [bottom, setBottom] = React.useState(50);
    return (
        <div>
            <Modal
                title={
                    action
                        ? t("edit") +
                          " " +
                          t("informationCabinClass").toLowerCase()
                        : t("informationCabinClass")
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}
                    <Divider />
                    <Affix offsetBottom={bottom}>
                        {action ? (
                            <Form.Item
                                wrapperCol={{
                                    span: 12,
                                    offset: 16,
                                }}
                            >
                                <ButtonSubmit
                                    onFinish={onFinish}
                                    handleClear={handleClear}
                                    handleCancels={handleCancels}
                                />
                            </Form.Item>
                        ) : (
                            ""
                        )}
                    </Affix>
                </Box>
            </Modal>
        </div>
    );
};
export default ModalLpAction;
