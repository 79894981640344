import { MinusCircleOutlined } from "@ant-design/icons";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import {
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Switch,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import "../../../../assets/css/supplier/oto/dvCc/modal.css";
import {
    API_BASE_URL,
    data_getDM,
    MAX_HEIGHT_SELECT,
} from "../../../../constants";
import ButtonSubmit from "../../../layout/ButtonSubmit";
import dayjs from "dayjs";
import InputMui from "../../../layout/InputMui";
import SingleSelectMui from "../../../layout/SingleSelectMui";

const { RangePicker } = DatePicker;

const ModalPR = ({
    isOpen,
    handleCancel,
    handleFinish,
    dvId,
    dvName,
    action,
    record,
    urlMap,
}) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [maDichVu, setMaDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [tenDichVu, setTenDichVu] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    const [maChuyenTau, setMaChuyenTau] = useState("");
    const [maChuyenTauErr, setMaChuyenTauErr] = useState(false);
    const [dmQuocGiaIdDi, setDmQuocGiaIdDi] = useState("");
    const [dmTinhIdDi, setDmTinhIdDi] = useState("");
    const [dmQuocGiaIdDiErr, setDmQuocGiaIdDiErr] = useState(false);
    const [dmTinhIdDiErr, setDmTinhIdDiErr] = useState(false);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dmQuocGiaIdDenErr, setDmQuocGiaIdDenErr] = useState(false);
    const [dataTinhTpDi, setDataTinhTpDi] = useState([]);
    const [dataTinhTpDen, setDataTinhTpDen] = useState([]);
    const [dmQuocGiaIdDen, setDmQuocGiaIdDen] = useState("");
    const [dmTinhIdDen, setDmTinhIdDen] = useState("");
    const [timeErr, setTimetErr] = useState(false);
    const [dmTinhIdDenErr, setDmTinhIdDenErr] = useState(false);
    const [dmLoaiVisaId, setDmLoaiVisaId] = useState("");
    const [visa, setVisa] = useState([]);
    const [dmLoaiVisaIdErr, setDmLoaiVisaIdErr] = useState(false);
    const [company, setCompany] = useState();
    const [dataCompany, setDataCompany] = useState([]);
    const [companyErr, setCompanyErr] = useState([]);
    const [ngoaiNguError, setNgoaiNguError] = useState(false);
    const [ngoaiNgu, setNgoaiNgu] = useState([]);
    const [ngoaiNguId, setNgoaiNguId] = useState("");
    const [hangError, setHangError] = useState(false);
    const [dataHang, setDataHang] = useState([]);
    const [hangId, setHangId] = useState("");
    const [id, setId] = useState(null);
    useEffect(() => {
        // handleClear()
        if (record) apiGetData(record.idPK);
    }, [record]);
    const apiGetData = useCallback((newId) => {
        axios({
            method: "post",
            url: urlMap + "getDataById",
            data: {
                id: newId,
            },
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    var items = [];
                    console.log("Response getDataById: ====", data);
                    let dataFit = [];
                    switch (dvName) {
                        case "ve-tau":
                            dataFit = data.tranxVeTauRequests;
                            break;
                        case "may-bay":
                            dataFit = data.tranxDvMayBayTimeRequests;
                            break;
                        case "ve-thang-canh":
                            dataFit = data.tranxVeThangCanhRequests;
                            break;
                        case "visa":
                            dataFit = data.tranxDvVisaTimeRequests;
                            break;
                        case "huong-dan-vien":
                            dataFit = data.tranxDichVuHdvRequests;
                            break;
                        case "ve-thuyen":
                            dataFit = data.tranxVeThuyenRequests;
                            break;
                        case "ve-bus":
                            dataFit = data.tranxVeXeBusRequests;
                            break;
                        case "dich-vu-khac":
                            dataFit = data.tranxDichVuKhacRequests;
                            break;
                        case "huong-dan-vien-tu-do":
                            dataFit = data.tranxDichVuHdvRequests;
                            break;
                        default:
                            break;
                    }
                    if (dataFit.length > 0) {
                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            items.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(
                                        new Date(
                                            tuNgay[2],
                                            tuNgay[1] - 1,
                                            tuNgay[0]
                                        )
                                    ),
                                    dayjs(
                                        new Date(
                                            denNgay[2],
                                            denNgay[1] - 1,
                                            denNgay[0]
                                        )
                                    ),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                                ve:
                                    dataFit[k].ve ||
                                    dataFit[k].dieuKienFoc ||
                                    dataFit[k].visa,
                                foc: dataFit[k].foc,
                            });
                            dataArr.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(
                                        new Date(
                                            tuNgay[2],
                                            tuNgay[1] - 1,
                                            tuNgay[0]
                                        )
                                    ),
                                    dayjs(
                                        new Date(
                                            denNgay[2],
                                            denNgay[1] - 1,
                                            denNgay[0]
                                        )
                                    ),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                                ve:
                                    dataFit[k].ve ||
                                    dataFit[k].dieuKienFoc ||
                                    dataFit[k].visa,
                                foc: dataFit[k].foc,
                            });
                        }
                    }
                    form.setFieldsValue({
                        items: items,
                    });
                    setId(data.id);
                    setMaDichVu(data.maDichVu);
                    setTenDichVu(data.tenDichVu);
                    setDmTyGiaId(data.dmTyGiaId);
                    setGhiChu(data.ghiChu);
                    setMaChuyenTau(data.maChuyenTau);
                    setCompany(data.tenCongTy);
                    setNgoaiNguId(data.dmNgoaiNguId);
                    setDmLoaiVisaId(data.dmLoaiVisaId);
                    setHangId(data.dmHangHangKhongId);
                    handleChangeQGDi(data.diDmQuocGiaId, data.diDmTinhId);
                    handleChangeQGDi(data.dmQuocGiaId, data.dmTinhId);
                    handleChangeQGDen(data.denDmQuocGiaId, data.denDmTinhId);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        form.setFieldsValue({
            items: [
                {
                    id: 0,
                    nccDichVuOtoId: "",
                    trangThaiBooking: true,
                    giaNet: "",
                    giaBan: "",
                    ve: "",
                    foc: "",
                },
            ],
        });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmVisa",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].ten,
                            });
                        }
                        setVisa(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmHangHangKhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].ten,
                                });
                            }
                        }
                        setDataHang(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].ngoai_ngu,
                            });
                        }
                        setNgoaiNgu(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    const onFinish = async (values) => {
        setTimetErr(false);
        setMaDichVuErr(false);
        setDmQuocGiaIdDenErr(false);
        setDmQuocGiaIdDiErr(false);
        setDmTinhIdDiErr(false);
        setMaChuyenTauErr(false);
        setTenDichVuErr(false);
        setCompanyErr(false);
        setNgoaiNguError(false);
        setHangError(false);
        if (!hangId) setHangError(true);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }

        if (!dmTinhIdDi) {
            setDmTinhIdDiErr(true);
        }
        if (!dmTinhIdDi) {
            setDmTinhIdDiErr(true);
        }
        if (!dmQuocGiaIdDi) {
            setDmQuocGiaIdDiErr(true);
            error = true;
        }

        if (dvName === "ve-tau") {
            if (!dmTinhIdDen) {
                setDmTinhIdDenErr(true);
            }
            if (!dmQuocGiaIdDen) {
                setDmQuocGiaIdDenErr(true);
                error = true;
            }
            if (!maChuyenTau) {
                setMaChuyenTauErr(true);
                error = true;
            }
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!company) {
            setCompanyErr(true);
            error = true;
        }
        if (!ngoaiNguId) {
            setNgoaiNguError(true);
            error = true;
        }
        var timeErr = [];
        var tranxDv = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var time = element.rangeTime ? false : true;
                var giaBan = element.giaBan ? false : true;
                var giaNet = element.giaNet ? false : true;

                timeErr.push({
                    time: time,
                    giaBan: giaBan,
                    giaNet: giaNet,
                });
                if (!time && !giaBan && !giaNet) {
                    error = false;
                    if (dvName === "visa") {
                        element.visa = element.ve;
                        element.ve = undefined;
                    }
                    tranxDv.push({
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        giaNet: element.giaNet,
                        giaBan: element.giaBan,
                        visa: element.visa,
                        ve: element.ve,
                        foc: element.foc,
                        trangThaiBooking:
                            element.trangThaiBooking ||
                            element.trangThaiBooking === undefined,
                    });
                } else error = true;
            }
        }
        setTimetErr(timeErr);
        if (error) return;
        let data = {
            id: id || 0, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: dvId,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maDichVu: maDichVu,
            tenDichVu: tenDichVu,
            ghiChu: ghiChu,
            loaiTien: tiGia[dmTyGiaId ? dmTyGiaId : 1].label,
            dmLoaiVisaId: dmLoaiVisaId,
            dmNgoaiNguId: ngoaiNguId,
            dmHangHangKhongId: hangId,
        };
        if (dvName === "may-bay") {
            data = {
                ...data,
                maChuyenTau: maChuyenTau,
                diemDi:
                    dataQuocGia.find((d) => d.value === dmQuocGiaIdDi).label +
                    " - " +
                    dataTinhTpDi.find((d) => d.value === dmTinhIdDi).label,
                diDmQuocGiaId: dmQuocGiaIdDi,
                diDmTinhId: dmTinhIdDi,
                diemDen:
                    dataQuocGia.find((d) => d.value === dmQuocGiaIdDen).label +
                    " - " +
                    dataTinhTpDen.find((d) => d.value === dmTinhIdDen).label,
                denDmQuocGiaId: dmQuocGiaIdDen,
                denDmTinhId: dmTinhIdDen,
                tranxDvMayBayTimeRequests: tranxDv,
            };
        }
        if (dvName === "ve-tau") {
            data = {
                ...data,
                maChuyenTau: maChuyenTau,
                diemDi:
                    dataQuocGia.find((d) => d.value === dmQuocGiaIdDi).label +
                    " - " +
                    dataTinhTpDi.find((d) => d.value === dmTinhIdDi).label,
                diDmQuocGiaId: dmQuocGiaIdDi,
                diDmTinhId: dmTinhIdDi,
                diemDen:
                    dataQuocGia.find((d) => d.value === dmQuocGiaIdDen).label +
                    " - " +
                    dataTinhTpDen.find((d) => d.value === dmTinhIdDen).label,
                denDmQuocGiaId: dmQuocGiaIdDen,
                denDmTinhId: dmTinhIdDen,
                tranxVeTauRequests: tranxDv,
            };
        }
        if (dvName === "ve-thang-canh") {
            data = {
                ...data,
                diaDiem:
                    dataQuocGia.find((d) => d.value === dmQuocGiaIdDi).label +
                    " - " +
                    dataTinhTpDi.find((d) => d.value === dmTinhIdDi).label,
                dmQuocGiaId: dmQuocGiaIdDi,
                dmTinhId: dmTinhIdDi,
                tranxVeThangCanhRequests: tranxDv,
            };
        }

        if (dvName === "visa") {
            data.tranxDvVisaTimeRequests = tranxDv;
        }
        if (dvName === "ve-thuyen") {
            data.tranxVeThuyenRequests = tranxDv;
        }
        if (dvName === "huong-dan-vien") {
            data.tranxDichVuHdvRequests = tranxDv;
        }
        if (dvName === "huong-dan-vien-tu-do") {
            data.tranxDichVuHdvRequests = tranxDv;
        }
        if (dvName === "dich-vu-khac") {
            data.tranxDichVuKhacRequests = tranxDv;
        }
        if (dvName === "ve-bus") {
            data.tranxVeXeBusRequests = tranxDv;
        }
        form.resetFields();
        setOpen(false);
        let check = await handleFinish(false, data);
        if (check) {
            handleClear();
        }
    };
    const handleCancels = () => {
        // form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        html = (
            <div className="">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                    }}
                                >
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col
                                                        xs={14}
                                                        sm={14}
                                                        md={14}
                                                        lg={14}
                                                        xl={14}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                "rangeTime",
                                                            ]}
                                                            label={
                                                                t(
                                                                    "hotel.priceRoom.applicationTime"
                                                                ) + " *"
                                                            }
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t(
                                                                            "validate"
                                                                        ) +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                placeholder={[
                                                                    t("toDate"),
                                                                    t(
                                                                        "fromDate"
                                                                    ),
                                                                ]}
                                                                disabledDate={(
                                                                    current
                                                                ) => {
                                                                    let customDate =
                                                                        moment().format(
                                                                            "DD-MM-YYYY"
                                                                        );
                                                                    return (
                                                                        current &&
                                                                        current <
                                                                            moment(
                                                                                customDate,
                                                                                "DD-MM-YYYY"
                                                                            )
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={5}
                                                        sm={5}
                                                        md={5}
                                                        lg={5}
                                                        xl={5}
                                                    ></Col>
                                                    <Col
                                                        xs={5}
                                                        sm={5}
                                                        md={5}
                                                        lg={5}
                                                        xl={2}
                                                    >
                                                        {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col
                                                        xs={11}
                                                        sm={11}
                                                        md={11}
                                                        lg={11}
                                                        xl={11}
                                                    >
                                                        <Row>
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "giaNet",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t(
                                                                                    "validate"
                                                                                ) +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputMui
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        defaultValue={
                                                                            ""
                                                                        }
                                                                        label={
                                                                            t(
                                                                                "hotel.priceClass.giaNet"
                                                                            ) +
                                                                            " *"
                                                                        }
                                                                        error={
                                                                            timeErr &&
                                                                            timeErr[
                                                                                key
                                                                            ]
                                                                                ?.giaNet
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    ></InputMui>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col
                                                        xs={11}
                                                        sm={11}
                                                        md={11}
                                                        lg={11}
                                                        xl={11}
                                                    >
                                                        <Row>
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "giaBan",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t(
                                                                                    "validate"
                                                                                ) +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputMui
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        defaultValue={
                                                                            ""
                                                                        }
                                                                        label={
                                                                            t(
                                                                                "hotel.priceClass.giaBan"
                                                                            ) +
                                                                            " *"
                                                                        }
                                                                        error={
                                                                            timeErr &&
                                                                            timeErr[
                                                                                key
                                                                            ]
                                                                                ?.giaBan
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    ></InputMui>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {dvName !== "huong-dan-vien" &&
                                                dvName !==
                                                    "huong-dan-vien-tu-do" ? (
                                                    <Row>
                                                        <Col
                                                            xs={9}
                                                            sm={9}
                                                            md={9}
                                                            lg={9}
                                                            xl={9}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    "foc",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message:
                                                                            t(
                                                                                "validate"
                                                                            ) +
                                                                            " " +
                                                                            t(
                                                                                "hotel.roomClass.conditionFoc"
                                                                            ).toLowerCase(),
                                                                    },
                                                                ]}
                                                            >
                                                                <InputMui
                                                                    sx={{
                                                                        marginTop: 1,
                                                                    }}
                                                                    defaultValue={
                                                                        ""
                                                                    }
                                                                    label={t(
                                                                        "hotel.roomClass.conditionFoc"
                                                                    )}
                                                                ></InputMui>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                                marginTop:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {dvName ===
                                                            "dich-vu-khac"
                                                                ? t("service")
                                                                : t("ticket")}
                                                        </Col>
                                                        <Col
                                                            xs={5}
                                                            sm={5}
                                                            md={5}
                                                            lg={5}
                                                            xl={5}
                                                        >
                                                            <Row>
                                                                <Col
                                                                    xs={24}
                                                                    sm={24}
                                                                    md={24}
                                                                    lg={15}
                                                                    xl={24}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            "ve",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: false,
                                                                                message:
                                                                                    t(
                                                                                        "validate"
                                                                                    ) +
                                                                                    " " +
                                                                                    t(
                                                                                        "ticket"
                                                                                    ).toLowerCase(),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputMui
                                                                            sx={{
                                                                                marginTop: 1,
                                                                            }}
                                                                            defaultValue={
                                                                                ""
                                                                            }
                                                                            // label={t("ticket")}
                                                                        ></InputMui>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                                marginTop:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {t(
                                                                "hotel.roomClass.foc"
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <></>
                                                )}
                                                <Row>
                                                    <Form.Item
                                                        {...restField}
                                                        valuePropName="checked"
                                                        name={[
                                                            name,
                                                            "trangThaiBooking",
                                                        ]}
                                                        label={
                                                            t("status") +
                                                            " " +
                                                            "booking"
                                                        }
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message:
                                                                    t(
                                                                        "validate"
                                                                    ) +
                                                                    " " +
                                                                    t(
                                                                        "hotel.priceRoom.applicationTime"
                                                                    ).toLowerCase(),
                                                            },
                                                        ]}
                                                    >
                                                        <Switch
                                                            defaultChecked={
                                                                true
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Row>
                                            </Card>
                                        )
                                    )}
                                    <Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                            >
                                                {t("add") +
                                                    " " +
                                                    t("time").toLowerCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TextField
                            label={t("notes")}
                            sx={{
                                marginTop: 2,
                            }}
                            value={ghiChu ? ghiChu : ""}
                            fullWidth
                            // multiline
                            // rows={5}
                            onChange={(e) => setGhiChu(e.target.value)}
                        ></TextField>
                    </Col>
                </Row>
            </div>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const handleChangeQGDi = async (value, tinh) => {
        if (!value) return;
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        await axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            console.log("Response: ====" + response.status);
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    setDmQuocGiaIdDi(value);
                    setDataTinhTpDi(dataTp);
                    if (tinh) setDmTinhIdDi(tinh);
                }
            }
        });
    };
    const handleChangeQGDen = async (value, tinh) => {
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        await axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            console.log("Response: ====" + response.status);
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    setDmQuocGiaIdDen(value);
                    setDataTinhTpDen(dataTp);
                    if (tinh) setDmTinhIdDen(tinh);
                }
            }
        });
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    const handleClear = () => {
        setMaDichVu("");
        setTenDichVu("");
        setDmTyGiaId(1);
        setGhiChu("");
        setDmQuocGiaIdDi("");
        setDmTinhIdDi("");
        setDmQuocGiaIdDen("");
        setDmTinhIdDen("");
        setNgoaiNguId("");
        setCompany("");
        setMaChuyenTau("");
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setTimetErr(false);
        setMaDichVuErr(false);
        setDmQuocGiaIdDenErr(false);
        setDmQuocGiaIdDiErr(false);
        setDmTinhIdDiErr(false);
        setMaChuyenTauErr(false);
        setTenDichVuErr(false);
        setNgoaiNguError(false);
        setHangError(false);
        var error = false;
        if (!hangId) setHangError(true);
        if (!ngoaiNguId) setNgoaiNguError(true);
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }

        if (!dmTinhIdDi) {
            setDmTinhIdDiErr(true);
        }
        if (!dmQuocGiaIdDi) {
            setDmQuocGiaIdDiErr(true);
            error = true;
        }

        if (!dmTinhIdDen) {
            setDmTinhIdDenErr(true);
        }
        if (!dmQuocGiaIdDen) {
            setDmQuocGiaIdDenErr(true);
            error = true;
        }
        if (!maChuyenTau) {
            setMaChuyenTauErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        var timeErr = [];
        for (let i = 0; i < values.items.length; i++) {
            const element = values.items[i];
            var time = element.rangeTime ? false : true;
            var giaBan = element.giaBan ? false : true;
            var giaNet = element.giaNet ? false : true;

            timeErr.push({
                time: time,
                giaBan: giaBan,
                giaNet: giaNet,
            });
        }
        setTimetErr(timeErr);
    };

    return (
        <div>
            <Modal
                title={
                    action
                        ? t("edit") + " " + t("service").toLowerCase()
                        : isEmpty
                        ? t("add") + " " + t("service").toLowerCase()
                        : t("service")
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputMui
                                label={t("res.code") + " *"}
                                value={maDichVu ? maDichVu : ""}
                                fullWidth
                                onChange={(e) => setMaDichVu(e.target.value)}
                                error={maDichVuErr}
                                helperText={
                                    maDichVuErr
                                        ? t("validate") +
                                          " " +
                                          t("res.code").toLowerCase()
                                        : ""
                                }
                            ></InputMui>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        {dvName === "ve-tau" ? (
                            <>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <InputMui
                                        label={t("trainCode") + " *"}
                                        value={maChuyenTau ? maChuyenTau : ""}
                                        fullWidth
                                        onChange={(e) =>
                                            setMaChuyenTau(e.target.value)
                                        }
                                        error={maChuyenTauErr}
                                        helperText={
                                            maChuyenTauErr
                                                ? t("validate") +
                                                  " " +
                                                  t("trainCode").toLowerCase()
                                                : ""
                                        }
                                    ></InputMui>
                                </Col>
                            </>
                        ) : dvName === "may-bay" ? (
                            <>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <SingleSelectMui
                                        label={t("airline") + " *"}
                                        value={hangId ? hangId : ""}
                                        options={dataHang}
                                        fullWidth
                                        onChange={(e) =>
                                            setHangId(e.target.value)
                                        }
                                        error={hangError}
                                        helperText={
                                            hangError
                                                ? t("validate") +
                                                  " " +
                                                  t("airline").toLowerCase()
                                                : ""
                                        }
                                    ></SingleSelectMui>
                                </Col>
                            </>
                        ) : dvName === "huong-dan-vien" ||
                          dvName === "huong-dan-vien-tu-do" ? (
                            <>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <SingleSelectMui
                                        label={t("language") + " *"}
                                        helperText={
                                            ngoaiNguError
                                                ? t("validate") +
                                                  " " +
                                                  t("language").toLowerCase()
                                                : ""
                                        }
                                        value={ngoaiNguId}
                                        onChange={(e) =>
                                            setNgoaiNguId(e.target.value)
                                        }
                                        error={ngoaiNguError}
                                        options={ngoaiNgu}
                                        fullWidth
                                    ></SingleSelectMui>
                                </Col>
                            </>
                        ) : dvName === "visa" ? (
                            <>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <SingleSelectMui
                                        label={t("visaType") + " *"}
                                        value={dmLoaiVisaId ? dmLoaiVisaId : ""}
                                        options={visa}
                                        fullWidth
                                        onChange={(e) =>
                                            setDmLoaiVisaId(e.target.value)
                                        }
                                        error={dmLoaiVisaIdErr}
                                        helperText={
                                            dmLoaiVisaIdErr
                                                ? t("validate") +
                                                  " " +
                                                  t("trainCode").toLowerCase()
                                                : ""
                                        }
                                    ></SingleSelectMui>
                                </Col>
                            </>
                        ) : dvName === "ve-thang-canh" ? (
                            <>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                    >
                                        <Row>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SingleSelectMui
                                                    label={t("location") + " *"}
                                                    value={
                                                        dmQuocGiaIdDi
                                                            ? dmQuocGiaIdDi
                                                            : ""
                                                    }
                                                    options={dataQuocGia}
                                                    fullWidth
                                                    onChange={(e) =>
                                                        handleChangeQGDi(
                                                            e.target.value,
                                                            null
                                                        )
                                                    }
                                                    error={dmQuocGiaIdDiErr}
                                                    helperText={
                                                        dmQuocGiaIdDiErr
                                                            ? t("validate") +
                                                              " " +
                                                              t(
                                                                  "point"
                                                              ).toLowerCase()
                                                            : ""
                                                    }
                                                ></SingleSelectMui>
                                            </Col>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SingleSelectMui
                                                    fullWidth
                                                    value={
                                                        dmTinhIdDi
                                                            ? dmTinhIdDi
                                                            : ""
                                                    }
                                                    options={dataTinhTpDi}
                                                    onChange={(e) =>
                                                        setDmTinhIdDi(
                                                            e.target.value
                                                        )
                                                    }
                                                    error={dmTinhIdDiErr}
                                                    helperText={
                                                        dmTinhIdDiErr
                                                            ? t("validate") +
                                                              " " +
                                                              t(
                                                                  "point"
                                                              ).toLowerCase()
                                                            : ""
                                                    }
                                                ></SingleSelectMui>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputMui
                                sx={{
                                    marginTop: 2,
                                }}
                                label={t("res.serviceProvided") + " *"}
                                value={tenDichVu ? tenDichVu : ""}
                                fullWidth
                                onChange={(e) => setTenDichVu(e.target.value)}
                                error={tenDichVuErr}
                                helperText={
                                    tenDichVuErr
                                        ? t("validate") +
                                          " " +
                                          t("res.serviceProvided").toLowerCase()
                                        : ""
                                }
                            ></InputMui>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <SingleSelectMui
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId}
                                        fullWidth
                                        options={tiGia}
                                        onChange={(e) =>
                                            setDmTyGiaId(e.target.value)
                                        }
                                        label={t("exchangeRate.currency")}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: {
                                                    height: MAX_HEIGHT_SELECT,
                                                },
                                            },
                                        }}
                                    ></SingleSelectMui>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                {dvName === "ve-tau" || dvName === "may-bay" ? (
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SingleSelectMui
                                            sx={{
                                                marginTop: 2,
                                            }}
                                            label={t("point") + " *"}
                                            value={
                                                dmQuocGiaIdDi
                                                    ? dmQuocGiaIdDi
                                                    : ""
                                            }
                                            options={dataQuocGia}
                                            fullWidth
                                            onChange={(e) =>
                                                handleChangeQGDi(
                                                    e.target.value,
                                                    null
                                                )
                                            }
                                            error={dmQuocGiaIdDiErr}
                                            helperText={
                                                dmQuocGiaIdDiErr
                                                    ? t("validate") +
                                                      " " +
                                                      t("point").toLowerCase()
                                                    : ""
                                            }
                                        ></SingleSelectMui>
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SingleSelectMui
                                            sx={{
                                                marginTop: 2,
                                            }}
                                            label="Tỉnh/Thành phố *"
                                            fullWidth
                                            value={dmTinhIdDi ? dmTinhIdDi : ""}
                                            options={dataTinhTpDi}
                                            onChange={(e) =>
                                                setDmTinhIdDi(e.target.value)
                                            }
                                            error={dmTinhIdDiErr}
                                            helperText={
                                                dmTinhIdDiErr
                                                    ? t("validate") +
                                                      " " +
                                                      t("point").toLowerCase()
                                                    : ""
                                            }
                                        ></SingleSelectMui>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <SingleSelectMui
                                                sx={{
                                                    marginTop: 2,
                                                }}
                                                label={t("destination") + " *"}
                                                value={
                                                    dmQuocGiaIdDen
                                                        ? dmQuocGiaIdDen
                                                        : ""
                                                }
                                                options={dataQuocGia}
                                                fullWidth
                                                onChange={(e) =>
                                                    handleChangeQGDen(
                                                        e.target.value,
                                                        null
                                                    )
                                                }
                                                error={dmQuocGiaIdDenErr}
                                                helperText={
                                                    dmQuocGiaIdDenErr
                                                        ? t("validate") +
                                                          " " +
                                                          t(
                                                              "destination"
                                                          ).toLowerCase()
                                                        : ""
                                                }
                                            ></SingleSelectMui>
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <SingleSelectMui
                                                sx={{
                                                    marginTop: 2,
                                                }}
                                                label="Tỉnh/Thành phố *"
                                                fullWidth
                                                value={
                                                    dmTinhIdDen
                                                        ? dmTinhIdDen
                                                        : ""
                                                }
                                                options={dataTinhTpDen}
                                                onChange={(e) =>
                                                    setDmTinhIdDen(
                                                        e.target.value
                                                    )
                                                }
                                                error={dmTinhIdDenErr}
                                                helperText={
                                                    dmTinhIdDenErr
                                                        ? t("validate") +
                                                          " " +
                                                          t(
                                                              "destination"
                                                          ).toLowerCase()
                                                        : ""
                                                }
                                            ></SingleSelectMui>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : null}
                <Form
                    layout="vertical"
                    form={form}
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    colon={false}
                    initialValues={{
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 11,
                        }}
                    >
                        <ButtonSubmit
                            onFinish={onFinish}
                            handleClear={handleClear}
                            handleCancels={handleCancels}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
