/**
 * A modal component for file import operations.
 *
 * @param {string} title - Đây là title lấy động của modal
 * @param {string[]} noteList - Đây là danh sách các note cần hiển thị trong modal
 * @param {boolean} isOpen - Đây là trạng thái của modal
 * @param {function} handleFinish - Đây là hàm xử lý khi nhấn nút OK
 * @param {function} handleCancel - Đây là hàm xử lý khi nhấn nút Cancel
 * @param {string} keySide - Đây là dmDichVuId
 * @param {string} name - đây là key để lấy data từ popUpImportFileConfig
 * @param {boolean} isDichVuKhac - Đây là biến kiểm tra xem có phải tab dichVuKhac không
 * @param {string} nccTtcId - Đây là nccTtcId
 *
 * @returns {JSX.Element} A Modal component for file import
 */

import {
    Button,
    Col,
    Divider,
    Modal,
    notification,
    Row,
    Typography,
    Upload,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, TIME_DURATION } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import axios from "axios";
import { CloudUploadOutlined } from "@ant-design/icons";

const { Link } = Typography;

const popUpImportFileConfig = {
    hotel: {
        type: "DS_KHACH_SAN",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    oto: {
        type: "DS_OTO",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    restaurant: {
        type: "DS_NHA_HANG",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    yacht: {
        type: "DS_DU_THUYEN",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    tourGuide: {
        type: "DS_HDV_CONG_TY",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    plane: {
        type: "DS_VE_MAY_BAY",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    boatTickets: {
        type: "DS_VE_THUYEN",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "visa-ttc": {
        type: "DS_VISA",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    scenicTickets: {
        type: "DS_VE_THANG_CANH",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    busTickets: {
        type: "DS_VE_BUS",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    trainTickets: {
        type: "DS_VE_TAU",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    otherServices: {
        type: "DS_DICH_VU_KHAC",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    landTour: {
        type: "DS_LAND_TOUR",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    freelanceTourGuide: {
        type: "DS_HDV_TU_DO",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    // ------------đây là phần của dịch vụ cung cấp-------------

    "hotel.roomClass": {
        type: "KS_HANG_PHONG",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    "hotel.roomPrice": {
        type: "KS_GIA_PHONG",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    "oto.dvcc": {
        type: "OTO_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    "restaurant.dvcc": {
        type: "NH_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    "duthuyen.roomPrice": {
        type: "DT_GIA_CABIN",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },

    "duthuyen.roomClass": {
        type: "DT_LOAI_CABIN",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "huong-dan-vien": {
        type: "HDV_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "may-bay": {
        type: "VMB_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "ve-thuyen": {
        type: "VTH_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    visa: {
        type: "VISA_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "ve-thang-canh": {
        type: "VTC_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "ve-bus": {
        type: "VB_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "ve-tau": {
        type: "VT_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "dich-vu-khac": {
        type: "DVK_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "huong-dan-vien-tu-do": {
        type: "HDV_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
    "landtour.dvcc": {
        type: "LT_DV_CUNG_CAP",
        urlImport: "ncc/files/import/excel",
        urlTemplate: "ncc/files/export/download-template",
    },
};

const getPopUpImportFileConfig = (key) => {
    return popUpImportFileConfig[key];
};

export default function ModalImportFile({
    title,
    noteList,
    isOpen,
    handleFinish,
    handleCancel,
    keySide,
    name,
    nccTtcId,
    isDichVuKhac,
}) {
    const [file, setFile] = useState();

    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();

    const { type, urlImport, urlTemplate } = getPopUpImportFileConfig(name);

    const upLoadProps = {
        beforeUpload: (file) => {
            const isExcel =
                file.type === "application/vnd.ms-excel" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

            if (!isExcel) {
                api["error"]({
                    message: t("not.filetype"),
                    description: t("not.filetype.mes"),
                    duration: TIME_DURATION,
                });
                setFile(null);
                return Upload.LIST_IGNORE;
            }

            setFile(file);
            return false;
        },
        onRemove: () => setFile(null),
        maxCount: 1,
    };

    const upLoadFile = async () => {
        if (!file) {
            console.log("No file to upload");
            return;
        }

        const fmData = new FormData();
        fmData.append("file", file);
        fmData.append("type", type);
        fmData.append("dmDichVuId", keySide);
        if (isDichVuKhac) fmData.append("isDichVuKhac", isDichVuKhac);

        if (nccTtcId) {
            fmData.append("nccTtcId", nccTtcId);
        }

        try {
            const response = await axios({
                method: "post",
                url: API_BASE_URL + urlImport,
                data: fmData,
                headers: {
                    ...authHeader(),
                    "Content-Type": "multipart/form-data",
                },
            });

            const { code, data } = response.data;

            if (code !== "00") {
                // handle error upload file
                api["error"]({
                    message: t("importfile.error"),
                    description: t("importfile.des"),

                    duration: TIME_DURATION,
                });

                if (!data) {
                    console.error("No data found in response!");
                    return;
                }

                const binaryData = atob(data);
                const arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }

                const blob = new Blob([arrayBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `template-fixed.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                api["success"]({
                    message: t("importfile.success"),
                    duration: TIME_DURATION,
                });
            }

            console.log("response: ====", response);
            handleFinish();
        } catch (error) {
            console.log("Error response: ====" + error);
        }
    };

    const onFinish = () => {
        setFile(null);
        handleFinish();
    };

    const handleCancels = () => {
        setFile(null);
        handleCancel();
    };

    const downloadTemplate = () => {
        const formData = new FormData();
        formData.append("type", type);

        axios({
            method: "post",
            url: API_BASE_URL + urlTemplate,
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((response) => {
                const { data } = response.data;
                if (!data) {
                    console.error("No data found in response!");
                    return;
                }

                const binaryData = atob(data);
                const arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }

                const blob = new Blob([arrayBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                const fullNameFileTemp =
                    (type.includes("DS")
                        ? t("import.template.ncc")
                        : isDichVuKhac
                        ? t("import.template.dvk")
                        : t("import.template.dvcc")) +
                    " - " +
                    t(name).charAt(0).toUpperCase() +
                    t(name).slice(1);

                link.setAttribute("download", `${fullNameFileTemp}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Download failed: ", error);
            });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={title}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={800}
                bodyStyle={{
                    height: 400,
                    background: "#f5f5f5",
                }}
            >
                <Row>
                    <Col>
                        <b>{t("note") + ": "}</b>
                    </Col>
                    <Col>
                        <ul>
                            {noteList.map((item, index) => {
                                if (index === noteList.length - 1) {
                                    return (
                                        <li>
                                            {item + " "}
                                            <Link
                                                onClick={downloadTemplate}
                                                target="_blank"
                                            >
                                                {t("here")}
                                            </Link>
                                        </li>
                                    );
                                }

                                return <li>{item}</li>;
                            })}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Divider>
                        <Upload {...upLoadProps}>
                            <Button
                                style={{ width: "700px", height: "80px" }}
                                icon={<CloudUploadOutlined />}
                            >
                                {t("buttonYploadTitle")}
                            </Button>
                        </Upload>
                    </Divider>
                </Row>
                <Row>
                    <Divider>
                        <Button
                            disabled={!file}
                            onClick={upLoadFile}
                            type="primary"
                        >
                            {t("action.ok")}
                        </Button>
                        <Button
                            style={{
                                marginLeft: "4px",
                            }}
                            onClick={handleCancels}
                            type="primary"
                        >
                            {t("action.cancel")}
                        </Button>
                    </Divider>
                </Row>
            </Modal>
        </>
    );
}
